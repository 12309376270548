<template>  
  <div id="app" style="width: 100%; height: 100%;">  
    <pdf style="height: 100%;"></pdf>  
  </div>  
</template>  

<script>  
import Pdf from './components/PdfComponent.vue';  

export default {  
  name: 'App',  
  components: {  
	Pdf
  },  
};  
</script>  