<template>
	<div class="image-gallery">
		<el-row :gutter="0" class="image-row" v-for="(imagePair, index) in imagePairs" :key="index">
			<el-col :span="isMobile ? 24 : 12" v-for="(image, imageIndex) in imagePair" :key="`${index}-${imageIndex}`"
				class="image-col">
				<el-image :src="image" class="image"></el-image>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		name: "ImageGallery",
		data() {
			return {
				images: [
					"http://open.yangxl.site/ydt/ydt_v2/img1.png",
					"http://open.yangxl.site/ydt/ydt_v2/img2.png",
					"http://open.yangxl.site/ydt/ydt_v2/img3.png",
					"http://open.yangxl.site/ydt/ydt_v2/img4.png",
					"http://open.yangxl.site/ydt/ydt_v2/img5.png",
					"http://open.yangxl.site/ydt/ydt_v2/img6.png",
					"http://open.yangxl.site/ydt/ydt_v2/img7.png",
					"http://open.yangxl.site/ydt/ydt_v2/img8.png",
					"http://open.yangxl.site/ydt/ydt_v2/img9.png",
					"http://open.yangxl.site/ydt/ydt_v2/img10.png",
					"http://open.yangxl.site/ydt/ydt_v2/img11.png",
					"http://open.yangxl.site/ydt/ydt_v2/img12.png",
					"http://open.yangxl.site/ydt/ydt_v2/img13.png",
					"http://open.yangxl.site/ydt/ydt_v2/img14.png",
					"http://open.yangxl.site/ydt/ydt_v2/img15.png",
					"http://open.yangxl.site/ydt/ydt_v2/img16.png",
					"http://open.yangxl.site/ydt/ydt_v2/img17.png",
					"http://open.yangxl.site/ydt/ydt_v2/img18.png",
				],
				isMobile: false,
			};
		},
		computed: {
			imagePairs() {
				// 将图片数组分成两两一组的子数组  
				return this.chunkArray(this.images, 2);
			},
		},
		mounted() {
			this.checkDevice();
			window.addEventListener('resize', this.checkDevice);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.checkDevice);
		},
		methods: {
			checkDevice() {
				// 简单的设备检查，可以根据需要扩展  
				this.isMobile = window.innerWidth < 768; // 假设768px以下是移动设备  
			},
			chunkArray(array, chunkSize) {
				// 将数组分割成指定大小的子数组  
				const chunks = [];
				for (let i = 0; i < array.length; i += chunkSize) {
					chunks.push(array.slice(i, i + chunkSize));
				}
				return chunks;
			},
		},
	};
</script>

<style scoped>
	.image-gallery {
		width: 100%;
	}

	.image-row {
		flex-wrap: nowrap;
		/* 防止换行 */
		height: 100vh;
	}

	.image-col {
		height: 100%;
		/* 设置列高为100% */
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.image {
		width: 100%;
		/* 设置图片宽度为100% */
		height: 100%;
		/* 设置图片高度为100% */
		object-fit: cover;
		/* 保持图片宽高比 */
	}
</style>